import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Landing from './pages/Landing';
import Feed from './pages/Feed';
import axios from 'axios';
import CreatePost from './pages/CreatePost';
import Profile from './pages/Profile';
import PostModal from './components/PostModal';
import Admin from './pages/Admin';
import StatsPage from './pages/StatsPage';
import File from './pages/Files';
import PasswordReset from './pages/PasswordReset';
import PublicPost from './pages/PublicPost';
import { api } from './api';
import Navbar from './components/Navbar';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // State
  const [user, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Authenticate User on Initial Load
  useEffect(() => {
    if (location.pathname !== '/') {
      authenticate();
    }

    const lastLog = sessionStorage.getItem('lastLog');
    const timeDiff = new Date().getTime() - lastLog;

    if (!lastLog || timeDiff > 600000) {
      logVisit();
    }
  }, []);

  // Re-authenticate on Route Change (excluding unauthenticated routes)
  useEffect(() => {
    const protectedRoutes = [
      '/feed',
      '/newpost',
      '/profile/:userID',
      '/post/:postID',
      '/admin',
      '/stats',
      '/NACS',
    ];

    const isProtected = protectedRoutes.some((route) =>
      location.pathname.startsWith(route.split(':')[0])
    );

    if (isProtected && !loading) {
      authenticate();
    }
  }, [location.pathname]);

  const authenticate = () => {
    setLoading(true);
    api
      .get('/me')
      .then((res) => {
        if (JSON.stringify(res.data) !== JSON.stringify(user)) {
          setUserData(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
        setUserData(null);
        if (!['/', '/reset', '/share'].includes(location.pathname)) {
          navigate('/');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const logVisit = () => {
    axios.post('/logVisit', {}, { withCredentials: true }).catch(console.error);
    sessionStorage.setItem('lastLog', Date.now());
  };

  return (
    <Routes>
      <Route path="/" exact element={<Landing />} />
      <Route
        path="/feed"
        element={
          <ProtectedRoute
            user={user}
            loading={loading}
            component={<Feed user={user} />}
          />
        }
      />
      <Route
        path="/newpost"
        element={
          <ProtectedRoute
            user={user}
            loading={loading}
            component={<CreatePost user={user} />}
          />
        }
      />
      <Route
        path="/profile/:userID"
        element={
          <ProtectedRoute
            user={user}
            loading={loading}
            component={<Profile user={user} />}
          />
        }
      />
      <Route
        path="/post/:postID"
        element={
          <ProtectedRoute
            user={user}
            loading={loading}
            component={<PostModal user={user} isPage={true} />}
          />
        }
      />
      <Route
        path="/admin"
        element={
          <ProtectedRoute
            user={user}
            loading={loading}
            component={<Admin user={user} />}
          />
        }
      />
      <Route
        path="/stats"
        element={
          <ProtectedRoute
            user={user}
            loading={loading}
            component={<StatsPage user={user} />}
          />
        }
      />
      <Route
        path="/NACS"
        exact
        element={
          <ProtectedRoute user={user} loading={loading} component={<File />} />
        }
      />
      <Route path="/reset" exact element={<PasswordReset />} />
      <Route path="/share" exact element={<PublicPost />} />
    </Routes>
  );
};

const ProtectedRoute = ({ user, loading, component }) => {
  if (loading) {
    return (
      <div>
        <Navbar user={user} />
        <div className="main-loading">
          <div className="loading-spinner">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="72"
              height="72"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-loader-circle"
            >
              <path d="M21 12a9 9 0 1 1-6.219-8.56" />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  if (!user) {
    return <div>You must be logged in to view this page.</div>; // Unauthorized message
  }

  return component;
};

export default App;
